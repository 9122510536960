import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { Color, Font, Opacity, rem, responsive, rgba } from "../../utils/style";
import Row from "../Row";

const StyledRow = styled(Row)``;

const StyledColumn = styled.div.attrs({
  className: "col-12 col-md-7 offset-md-5",
})``;

const ExpertsSection = styled.div`
  :first-of-type {
    padding-bottom: 40px;
  }

  h3 {
    color: ${Color.ritualBlue};
    font-size: ${rem(30)};
    font-weight: 500;
    letter-spacing: -0.4px;
    line-height: ${rem(36)};
    margin-bottom: 16px;

    ${responsive.md`
      font-size: ${rem(32)};
      letter-spacing: -1.4px;
      line-height: ${rem(54)};
    `}
  }

  p {
    ${Font.dutch};
    color: ${Color.ritualBlue};
    font-size: ${rem(18)};
    font-weight: normal;
    letter-spacing: 0px;
    line-height: ${rem(28)};
    margin-bottom: 32px;

    ${responsive.md`
      font-size: ${rem(20)};
      font-weight: normal;
      line-height: ${rem(30)};
      margin-bottom: 40px;
    `}
  }
`;

const ExpertsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;

  p {
    ${Font.circular}
  }
`;

const ExpertDefaultStyles = styled.div`
  p {
    &.expert-title {
      font-size: ${rem(16)};
      line-height: ${rem(26)};
      font-weight: 500;
      letter-spacing: 0px;
      margin-bottom: 16px;

      ${responsive.md`
        font-size: ${rem(20)};
        line-height: ${rem(30)};
      `};
    }

    &.expert-bio {
      color: ${rgba(Color.ritualBlue, Opacity.light)};
      font-size: ${rem(14)};
      line-height: ${rem(24)};
      font-weight: 300;
      letter-spacing: 0px;
      margin-bottom: 16px;

      ${responsive.md`
        font-size: ${rem(16)};
        line-height: ${rem(26)};
      `};
    }
  }

  span.expert-link {
    border-bottom: solid 2px #142b6f;
  }
`;

const MobileExpert = styled(ExpertDefaultStyles).attrs({
  className: "d-block d-md-none",
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  margin-bottom: 48px;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;

    .gatsby-image-wrapper {
      height: 84px;
      width: 84px;
      border-radius: 135px;
      margin-right: 14px;
      margin-bottom: 0;
    }
  }
`;

const DesktopExpert = styled(ExpertDefaultStyles).attrs({
  className: "d-none d-md-flex",
})`
  display: flex;
  flex-direction: row;
  margin-bottom: 56px;

  .gatsby-image-wrapper {
    border-radius: 135px;
    margin-right: 30px;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: top;
  }
`;

const Expert = ({ person }) => {
  const { image, name, title, bio, externalSourceTitle, externalSourceUrl } =
    person;
  if (!image || !name || !bio || !title) return null;

  const expertTitle = `${name}, ${title}`;

  const imageProps = {
    image: getImage(image),
    loading: "eager",
    alt: image.title,
    color: Color.white,
  };

  return (
    <>
      <MobileExpert>
        <div>
          <GatsbyImage
            {...imageProps}
            style={{
              position: "relative",
              width: "120px",
              height: "120px",
              minWidth: "120px",
              zIndex: "1",
              userSelect: "none",
              userDrag: "none",
              pointerEvents: "none",
              touchCallout: "none",
            }}
          />
          <p className="expert-title">{expertTitle}</p>
        </div>
        <p className="expert-bio">{bio.bio}</p>
        {externalSourceUrl && (
          <a href={externalSourceUrl}>
            <span className="expert-link">{externalSourceTitle}</span>
          </a>
        )}
      </MobileExpert>

      <DesktopExpert>
        <GatsbyImage
          {...imageProps}
          style={{
            position: "relative",
            width: "120px",
            height: "120px",
            minWidth: "120px",
            zIndex: "1",
            userSelect: "none",
            userDrag: "none",
            pointerEvents: "none",
            touchCallout: "none",
          }}
        />
        <div>
          <p className="expert-title">{expertTitle}</p>
          <p className="expert-bio">{bio.bio}</p>
          {externalSourceUrl && (
            <a href={externalSourceUrl}>
              <span className="expert-link">{externalSourceTitle}</span>
            </a>
          )}
        </div>
      </DesktopExpert>
    </>
  );
};

const ArticleExperts = ({ className, people, heading, subheading }) => {
  return (
    <StyledRow className={className}>
      <StyledColumn>
        <ExpertsSection>
          <h3>{heading}</h3>
          <p>{subheading}</p>
          {people.map((person, i) => (
            <ExpertsContainer key={`${person.name}${i}`}>
              <Expert person={person} />
            </ExpertsContainer>
          ))}
        </ExpertsSection>
      </StyledColumn>
    </StyledRow>
  );
};

export default ArticleExperts;
