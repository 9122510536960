import { graphql } from "gatsby";
import { createRef, createElement, Component } from "react";
import styled from "styled-components";
import Container from "../components/Container";
import StickyProductNav from "../components/global/StickyProductNav";
import ArticleBlockQuote from "../components/hub/ArticleBlockQuote";
import ArticleContentGeneric from "../components/hub/ArticleContentGeneric";
import ArticleEssentialTakeaways from "../components/hub/ArticleEssentialTakeaways";
import ArticleExperts from "../components/hub/ArticleExperts";
import ArticleHero from "../components/hub/ArticleHero";
import ArticleInterviewerBio from "../components/hub/ArticleInterviewerBio";
import ArticleMythFact from "../components/hub/ArticleMythFact";
import ArticleQA from "../components/hub/ArticleQA";
import ArticleReadProgress from "../components/hub/ArticleReadProgress";
import ArticleRelated from "../components/hub/ArticleRelated";
import ArticleShare from "../components/hub/ArticleShare";
import ArticleStandaloneAnswer from "../components/hub/ArticleStandaloneAnswer";
import ArticleStandaloneImage from "../components/hub/ArticleStandaloneImage";
import VideoOverlay from "../components/hub/VideoOverlay";
import PageHero from "../components/PageHero";
import ProductCategoryCards from "../components/product/ProductCategoryCards";
import ArticleSEO from "../components/seo/Article";
import Text from "../components/Text";
import intl from "../services/intl";
import { calculateReadingTime } from "../utils/readingTime";
import config from "../utils/siteConfig";

// Styled Elements
const ArticleContainer = styled(Container).attrs({
  role: "section",
})``;

const CardsSection = styled.section``;

var Components = {
  ContentfulInterviewQa: ArticleQA,
  ContentfulContentMythFact: ArticleMythFact,
  ContentfulContentInterviewStandaloneAnswer: ArticleStandaloneAnswer,
  ContentfulContentStandaloneImage: ArticleStandaloneImage,
  ContentfulContentBlockquote: ArticleBlockQuote,
  ContentfulContentGeneric: ArticleContentGeneric,
  ContentfulInterviewBio: ArticleInterviewerBio,
};

export default class Content extends Component {
  constructor(props) {
    super(props);

    let { title, pageTitle, slug, heroImage, metaDescription, structuredData } =
      this.props.data.contentfulArticle;

    this.state = {
      showVideoOverlay: false,
      seo: {
        pagePath: `${config.contentHubArticlesUrl}/${slug}`,
        title: pageTitle || `${title} - Ritual`,
        description: metaDescription?.metaDescription,
        image: {
          url: heroImage.share.src,
          width: heroImage.share.width,
          height: heroImage.share.height,
        },
        articleCustom: structuredData?.internal?.content
          ? JSON.parse(structuredData.internal.content)
          : null,
      },
    };

    this.videoOverlay = createRef();
  }

  componentDidMount() {
    this.props.updatePageData({
      label: "Article",
    });
  }

  showOverlay() {
    this.setState({
      showVideoOverlay: true,
    });
    if (this.videoOverlay) {
      this.videoOverlay.current.play();
    }
  }

  hideOverlay() {
    this.setState({
      showVideoOverlay: false,
    });
    if (this.videoOverlay) {
      this.videoOverlay.current.stop();
    }
  }

  renderComponent(componentNode, i, gutterContent) {
    let component = Components[componentNode.__typename];
    if (component) {
      let reactComponent = createElement(component, {
        data: componentNode,
        gutterContent: gutterContent,
        key: `component-` + i,
      });
      return reactComponent;
    } else {
      return null;
    }
  }

  render() {
    let { showVideoOverlay } = this.state;
    let { contentfulArticle } = this.props.data;
    let {
      articleVideo,
      primaryContent,
      essentialTakeaways,
      relatedArticles,
      experts,
      expertsHeading,
      expertsSubheading,
      authors,
      authorHeading,
      authorSubheading,
      heroBackgroundColor,
      desktopImage,
      mobileImage,
    } = contentfulArticle;
    const { location } = this.props;

    let readTime = calculateReadingTime(primaryContent);

    const productSku = location.state ? location.state.productSku : "";

    return (
      <>
        <ArticleSEO {...this.state.seo} />

        {productSku && (
          <StickyProductNav
            sku={productSku}
            ctaLocation={"Articles Sticky Nav"}
            scrollOffset={150}
            showLearnMore={true}
            showTopBar={true}
          />
        )}

        <article>
          <PageHero
            id="article-hero"
            backgroundColor={heroBackgroundColor}
            imageMobile={mobileImage}
            imageObjectPosition={"10% 50%"}
            imageDesktop={desktopImage}
          >
            <ArticleHero
              readTime={readTime}
              onClick={this.showOverlay.bind(this)}
              {...contentfulArticle}
            />
          </PageHero>

          <ArticleReadProgress />

          <ArticleContainer className="article-container">
            <h2 className="sr-only">
              <Text
                id="template.article.content.heading"
                defaultMessage="Article Content"
              />
            </h2>
            {primaryContent &&
              primaryContent.map((c, i) => {
                let gutterContent =
                  i === 0 ? (
                    <ArticleEssentialTakeaways content={essentialTakeaways} />
                  ) : (
                    false
                  );
                return this.renderComponent(c, i, gutterContent);
              })}

            {(experts || authors) && (
              <div className="mt-7">
                {experts && (
                  <ArticleExperts
                    people={experts}
                    heading={expertsHeading}
                    subheading={expertsSubheading}
                  />
                )}
                {authors && (
                  <ArticleExperts
                    people={authors}
                    heading={
                      authorHeading ||
                      intl.t("hub.author.heading", "Meet the Author")
                    }
                    subheading={
                      authorSubheading ||
                      intl.t(
                        "hub.author.subheading",
                        "This article was written by our content specialist.",
                      )
                    }
                  />
                )}
              </div>
            )}
            <ArticleShare data={contentfulArticle} />
          </ArticleContainer>
        </article>

        <CardsSection>
          <ProductCategoryCards className="mt-7 mt-md-9 mb-7 mb-md-9" />
        </CardsSection>

        <ArticleRelated data={relatedArticles} />

        {articleVideo && (
          <VideoOverlay
            ref={this.videoOverlay}
            articleVideo={articleVideo}
            onClick={this.hideOverlay.bind(this)}
            visible={showVideoOverlay}
          />
        )}
      </>
    );
  }
}

export const pageQuery = graphql`
  fragment expandPrimaryContent on ContentfulArticle {
    primaryContent {
      __typename
      ... on ContentfulContentGeneric {
        ...genericContentBlock
      }
      ... on ContentfulContentBlockquote {
        ...blockQuoteBlock
      }
      ... on ContentfulInterviewQa {
        ...articleQABlock
      }
      ... on ContentfulContentMythFact {
        ...articleMythFactBlock
      }
      ... on ContentfulContentInterviewStandaloneAnswer {
        ...articleAnswerBlock
      }
      ... on ContentfulContentStandaloneImage {
        ...standaloneImageBlock
      }
      ... on ContentfulInterviewBio {
        ...interviewerBioContentBlock
      }
    }
  }

  fragment articleProductLinks on ContentfulArticle {
    products {
      __typename
      ... on ContentfulProduct {
        name {
          name
          childMarkdownRemark {
            html
          }
        }
        slug
        shortDescription
        summary
        cardImage {
          title
          file {
            url
          }
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: DOMINANT_COLOR
            width: 570
            quality: 90
          )
        }
        cardBackgroundColor
      }
      ... on ContentfulProductCategory {
        categoryName
        cardImage {
          title
          description
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: DOMINANT_COLOR
            width: 580
            quality: 80
          )
        }
        backgroundColor
      }
    }
  }

  fragment expertContent on ContentfulExpert {
    name
    title
    titleSubhead
    bio {
      bio
      childMarkdownRemark {
        html
      }
    }
    image {
      title
      description
      gatsbyImageData(
        layout: CONSTRAINED
        placeholder: DOMINANT_COLOR
        width: 400
        quality: 90
      )
    }
    externalSourceTitle
    externalSourceUrl
  }

  query FreeformArticleQuery($locale: String!, $slug: String!) {
    contentfulArticle(node_locale: { eq: $locale }, slug: { eq: $slug }) {
      title
      category {
        title
      }
      pageTitle
      metaDescription {
        metaDescription
      }
      slug
      previewText
      heroBackgroundColor
      heroSecondaryColor
      essentialTakeaways {
        childMarkdownRemark {
          html
        }
      }
      desktopImage: heroImage {
        description
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: DOMINANT_COLOR
          width: 1920
          quality: 90
        )
      }
      mobileImage: heroImage {
        description
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: DOMINANT_COLOR
          height: 912
          quality: 90
        )
      }
      heroImage {
        file {
          url
        }
        share: resize(width: 1200, height: 630, quality: 90, cropFocus: LEFT) {
          src
          width
          height
        }
      }
      articleVideo {
        title
        description {
          description
        }
        videoLength
        sources {
          id
          file {
            url
            contentType
          }
          description
        }
        captions {
          file {
            url
          }
          description
        }
      }
      ...expandPrimaryContent
      ...articleProductLinks
      expertsHeading
      expertsSubheading
      experts {
        ...expertContent
      }
      authorHeading
      authorSubheading
      authors {
        ...expertContent
      }
      relatedArticles {
        ...featuredArticlesFragment
        ...expandPrimaryContent
        category {
          slug
          title
        }
        heroImage {
          file {
            url
          }
          desktop: gatsbyImageData(
            layout: CONSTRAINED
            width: 960
            height: 560
            quality: 100
            cropFocus: LEFT
            resizingBehavior: FILL
          )
          mobile: gatsbyImageData(
            layout: CONSTRAINED
            width: 960
            height: 560
            quality: 100
            cropFocus: LEFT
            resizingBehavior: FILL
          )
        }
      }
      structuredData {
        internal {
          content
        }
      }
    }
  }
`;
