import { StaticQuery, graphql } from "gatsby";
import { useRef, useState } from "react";
import styled, { css } from "styled-components";

// Services
import intl from "../../services/intl";
import { variation } from "../../services/launchDarkly";

// Utils
import useVariation from "../../hooks/useVariation";
import metrics from "../../utils/metrics";
import { orderArray } from "../../utils/order";
import { Color, Font, Opacity, rem, responsive } from "../../utils/style";

// Components
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Container from "../Container";
import MagicLink from "../MagicLink";
import Row from "../Row";
import ScrollableOverflow from "../ScrollableOverflow";
import Ribbon from "../global/Ribbon";

// Icons
import ArrowLeftLarge from "../../../static/react-svg/arrow-left-large.svg";
import ArrowRightLarge from "../../../static/react-svg/arrow-right-large.svg";
import Text from "../Text";

const Wrapper = styled.section``;

const CategoryCardsContainer = styled(Container)`
  padding: 0 20px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: visible !important;

  ${responsive.sm`
    padding: 0px;
  `}
`;

const baseStyles = css`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: calc(100vw * (84 / 320));
  margin-right: calc(100vw * (8 / 320));
  background-color: rgba(242, 241, 245, ${Opacity.light});

  :last-of-type {
    margin-right: 0;
  }

  p {
    ${Font.circular};
    color: ${Color.ritualBlue};
    font-size: ${rem(14)};
    line-height: ${rem(20)};
    font-weight: 300;
    letter-spacing: 0px;
    text-align: center;
    margin: 0;
    padding: 6px 0;
  }

  ${responsive.sm`
    width: 160px;
    margin-right: 20px;

    p:before {
      content: "Shop ";
    }

    img {
      transition: transform 0.2s ease-in !important;
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  `}

  ${responsive.md`
    width: 214px;
    margin-right: 26px;

    p {
      font-size: ${rem(18)};
      line-height: ${rem(24)};
      padding: 12px 0;
    }
  `}

  ${responsive.lg`
    width: 270px;
    margin-right: 30px;
  `}
`;

const SlideWrapper = styled.div`
  ${baseStyles};
`;

const ScrollableSlide = styled(MagicLink)``;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  img {
    background-color: ${Color.ritualYellow};
  }

  span {
    position: absolute;
    bottom: 4px;
    left: 4px;
  }

  ${responsive.sm`
    height: 82px;

    span {
      bottom: 8px;
      left: 8px;
    }
  `}

  ${responsive.md`
    height: 110px;

    span {
      bottom: 12px;
      left: 12px;
    }
  `}

  ${responsive.lg`
    height: 140px;
  `}
`;

const RibbonStyle = css`
  height: 20px;
  padding: 0px 8px;
  font-size: ${rem(8)};
  line-height: ${rem(16)};
  letter-spacing: 0.53px;

  &::before,
  &::after {
    border-width: 10px 4px;
  }

  ${responsive.md`
    height: 30px;
    padding: 0 14px;
    font-size: ${rem(10)};
    letter-spacing: 0.67px;

    &::before,
    &::after {
      border-width: 15px 8px;
    }
  `}
`;

const Controls = styled.div`
  margin-bottom: 24px;
  padding: 0 20px;

  ${responsive.sm`
    padding: 0;
  `}

  ${responsive.md`
    margin-bottom: 40px;
  `};

  h2 {
    ${Font.circular};
    color: ${Color.ritualBlue};
    font-size: ${rem(20)};
    line-height: ${rem(25)};
    margin-bottom: 0 !important;

    ${responsive.md`
      font-size: ${rem(24)};
      line-height: ${rem(30)};
    `}
  }
`;

const ArrowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  button {
    &:first-child {
      margin-right: 28px;
      ${responsive.md`
        margin-right: 36px;
      `};
    }
  }
`;

const ArrowButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  position: relative;

  width: 24px;
  height: 24px;

  svg {
    height: 14px;
    width: 20px;
  }

  &[disabled] {
    svg {
      opacity: 0.5;
    }
  }
`;

const categoriesFilter = [
  "357129QL34LCalkG3TeIsT", // Bundle
  "6rJs8SzkA0sEphJEzuOKfH", // Multivitamins
  "3pskaPDeDeWHJ48CrMJG1I", // Pregnancy
  "3o903h27mp8qfyAhmMQjED", // Stress
  "1b0toQsOf42guVnMqU1B3z", // Gut Health
  "5FzlXGv1hVyvpdmHHr8ww6", // Skin
  "30ATxQydyyUtAzUy8eCwjR", // Protein
];

const Slide = ({ slide }) => {
  const handleClick = (name) => {
    metrics.track("CTA Clicked", {
      title: name,
      location: "Product Category Card",
      nonInteraction: false,
    });
  };

  return (
    <ScrollableSlide to={slide.url} onClick={() => handleClick(slide.title)}>
      <ImageContainer>
        <GatsbyImage
          image={getImage(slide.img)}
          loading="eager"
          backgroundColor={Color.ritualYellow}
          alt={slide.altText}
          style={{
            userSelect: "none",
            userDrag: "none",
            pointerEvents: "none",
            touchCallout: "none",
          }}
          imgStyle={{
            objectPosition: "top center",
          }}
        />
        {slide.flag && (
          <Ribbon contentStyle={RibbonStyle} white={true}>
            {slide.flag}
          </Ribbon>
        )}
      </ImageContainer>
      <p>{slide.title}</p>
    </ScrollableSlide>
  );
};

// Hook component
export const ProductCategoryCards = ({ data, className }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const slideRef = useRef(null);
  const allCategoryNodes = intl.cf(data.allContentfulProductCategory.nodes);
  const stressFlagEnabled = useVariation("stress-relief-launch", true);
  const showBundleCard = useVariation("bundle-category-card", false);

  let filter = [...categoriesFilter];
  if (!showBundleCard) {
    filter = filter.filter((id) => id !== categoriesFilter[0]);
  }

  const filteredCategoryNodes = allCategoryNodes.filter((node) =>
    filter.includes(node.contentful_id),
  );

  const orderedNodes = orderArray(
    filteredCategoryNodes,
    filter,
    "contentful_id",
  );

  const slides = orderedNodes
    ?.map((node) => {
      const isPregnancy = node.shortName === "Pregnancy";
      const isStress = node.shortName === "Stress Relief";
      const isBundle = node.shortName === "Bundles";

      let flag =
        (!stressFlagEnabled && isPregnancy)
          ? "New Item"
          : (!!stressFlagEnabled && isStress) ? "New" : "";
      if (isBundle) {
        flag = "Save";
      }

      let shopLandingSlug = node.shopLandingReference?.slug;
      if (node.shortName === "Pregnancy") {
        shopLandingSlug = "shop/first-to-third-trimester";
      }
      if (stressFlagEnabled && isStress) {
        shopLandingSlug = "products/stress-relief-bioseries";
      }
      let url = `/${shopLandingSlug}`;

      return {
        title: node.shortName,
        url,
        altText: `Shop ${node.shortName}`,
        img: node.cardImage,
        flag,
      };
    })
    .filter((slide) => slide);

  const handleScroll = (index) => {
    slideRef.current.scrollToIndex(index, "left");
  };

  const handleLeft = () => {
    setSlideIndex(0);
    handleScroll(0);
  };

  const handleRight = () => {
    setSlideIndex(slides.length - 1);
    handleScroll(slides.length - 1);
  };

  if (!slides || slides.length < 1) return null;

  return (
    <Wrapper className={className}>
      <Container>
        <Row>
          <Controls className="col-12 d-flex justify-content-between">
            <h2>
              <Text
                id="category-card.section.heading"
                defaultMessage="Find your Ritual"
              />
            </h2>
            <ArrowWrapper>
              <ArrowButton disabled={slideIndex === 0} onClick={handleLeft}>
                <ArrowLeftLarge />
              </ArrowButton>
              <ArrowButton
                disabled={slideIndex === slides.length - 1}
                onClick={handleRight}
              >
                <ArrowRightLarge />
              </ArrowButton>
            </ArrowWrapper>
          </Controls>
        </Row>
      </Container>
      <CategoryCardsContainer>
        <ScrollableOverflow
          key={`test-key-djksdjkdas`}
          ref={slideRef}
          bleedLeft={true}
          bleedRight={true}
        >
          {slides.map((slide, i) => {
            return (
              <SlideWrapper key={`${slide.title}-${i}`}>
                <Slide slide={slide} />
              </SlideWrapper>
            );
          })}
        </ScrollableOverflow>
      </CategoryCardsContainer>
    </Wrapper>
  );
};

export const ProductCategoryCardsQuery = (props) => {
  return (
    <StaticQuery
      query={componentQuery}
      render={(data) => <ProductCategoryCards {...props} data={data} />}
    />
  );
};

export default ProductCategoryCardsQuery;

const componentQuery = graphql`
  query ProductCategoryCardsQuery {
    allContentfulProductCategory {
      nodes {
        node_locale
        shortName
        contentful_id
        cardImage {
          title
          description
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: DOMINANT_COLOR
            width: 580
            quality: 90
          )
        }
        backgroundColor
        shopLandingReference {
          slug
        }
        tags {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
    }
  }
`;
